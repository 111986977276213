<template>
  <div class="contacts">
    <div class="row row-cols-1 row-cols-lg-3 row-cols-xl-6 g-2">
      <MainTitleLight center v-if="!contacts || !contacts.length">
        Контакты отсутствуют
      </MainTitleLight>
      <ContactShort
        class="col"
        :contact="contact"
        v-for="(contact, key) in contacts"
        :key="`contact_${key}`"
      />
    </div>
  </div>
</template>

<script>
import ContactShort from "../components/contacts/ContactShort";
import loadingMixin from "../mixins/loadingMixin";
import API from "../common/api/api.functions";
import MainTitleLight from "../components/base/MainTitleLight";
export default {
  name: "Contacts",
  components: { MainTitleLight, ContactShort },
  mixins: [loadingMixin],
  data: () => ({
    contacts: []
  }),
  async mounted() {
    this.loading++;
    this.contacts = await API.getContacts().then(res => res.data);
    this.loading--;
  }
};
</script>

<style lang="scss">
.contacts {
  .row {
    justify-content: center;
  }
}
</style>
