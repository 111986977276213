<template>
  <div class="contact">
    <Avatar class="mb-4" :src="contact.avatar" size="100%" />
    <div class="name mb-1" v-if="contact.name">
      {{ contact.name }}
    </div>
    <div class="position mb-1" v-if="contact.role">{{ contact.role }}</div>
    <div class="position mb-1" v-if="contact.additional_text">
      <p
        v-for="(text, key) in (contact.additional_text || '').split(/[\n\r]/)"
        :key="`text_contact_${key}`"
      >
        {{ text }}
      </p>
    </div>
    <div class="phone mb-2" v-if="contact.phone">
      <a target="_blank" :href="`tel:${contact.phone}`">{{ contact.phone }}</a>
    </div>
    <div class="phone mb-2" v-if="contact.email">
      <a target="_blank" :href="`mailto:${contact.email}`">{{
        contact.email
      }}</a>
    </div>
    <div class="messengers row mb-4 g-2">
      <div class="col col-auto" v-if="contact.telegram">
        <a target="_blank" :href="`https://t.me/${contact.telegram}`">
          <SvgIcon icon="telegram" hover="primary" color="light" width="41" />
        </a>
      </div>
      <div class="col col-auto" v-if="contact.whatsapp">
        <a target="_blank" :href="`https://wa.me/${contact.whatsapp}`">
          <SvgIcon icon="whatsapp" hover="success" color="light" width="41" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "../base/Avatar";
import SvgIcon from "../base/SvgIcon";

export default {
  name: "ContactShort",
  components: { SvgIcon, Avatar },
  props: {
    contact: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
.contact {
  font-size: 20px;
  text-align: center;

  @include media-breakpoint-down("md") {
    font-size: 1rem;
  }

  .name {
    font-weight: bold;
    text-transform: uppercase;
  }

  .position,
  .phone {
    font-weight: 300;
    text-transform: lowercase;
  }

  .phone {
    text-decoration: underline;
  }

  .messengers {
    display: flex;
    justify-content: center;
  }
}
</style>
